import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function TrademarkRegistration() {
  /* Slider */
  // const tradeSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Trademark Registration`,
  //     content: `India's Best Online Trademark Registration Service`,
  //     image: "/imgs/registration/ipr/td4.png",
  //     alt_tag: "Best Online Trademark Registration Service Near Bangalore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trademark Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6499 All Inclusive`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Trademark
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Trademark Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `10% discount on 4+ 
          applications`,
        icon: "/imgs/icons/it/80u.png",
        alt_tag: "Discount",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const tradeAboutData = {
    header: `Online Trademark Registration`,
    sub_title: `Best Trademark Registration Service in Hosur.`,
    content_paragraph: [
      `Trademark is a unique identity that sets one product or service apart from comparable ones. A symbol, slogan, phrase, word, scent, 
      sound, color scheme, or other visual components might all be used as the distinguishing expression or identity. The vast majority of 
      companies opt to just register their name or logo.`,

      // `Trademark registering is the only way to ensure that your name or logo remains your own unique identification for many years.
      // `,
      // `Government of India, Ministry of Industry and Commerce, Trademarks. Additionally, it may be
      //  registered under The Trademark Act of 1999. The registration gives the business the legal
      //  authority to sue anyone who attempts to use your trademarked name, logo, or brand without your permission.`,
    ],
  };
  /* Scroll Nav Data */
  const MsMeScrollId = [
    {
      id: `#types`,
      heading: `Types of Trademark`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#benefitss`,
      heading: `Benefits`,
    },
    {
      id: `#documents`,
      heading: `Documents Required`,
    },
  ];

  /* Img Content Component Data */
  const tradeIcData = {
    id: 'types',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Types Of Trade Mark',
    image: '/imgs/gifs/llp-1.gif',
    alt_tag: 'Trademark Registration Procedure',
    Paragraph: `Obtaining a trademark registration online allows a company organization to utilize specific symbols or even words that 
    symbolize the firm and the products or services it provides. It functions as an asset, allowing the company to safeguard its brand 
    name, which is an important aspect of its credibility and identity.`,
    points: [
      {
        head: 'Trademark Types',
        content: ``,
        icon: false,
      },
      {
        content: `Sound mark`,
        icon: true,
      },
      {
        content: `Product mark`,
        icon: true,
      },
      {
        content: `Service mark`,
        icon: true,
      },
      {
        content: `Collective mark`,
        icon: true,
      },
      {
        content: `Certification mark`,
        icon: true,
      },
      {
        content: `Shape & pattern mark`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const tradeCfData = {
    id: "procedure",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Procedure Trademark Registration",
    content: [
      `Starting with the trademark search stage, the trademark(TM) director is used to perform the search. 
    It is crucial to do a thorough search of the trademark, logo, and brand name to choose the one that has not yet 
    been registered by someone or by any institution.`,
      `Once it is determined that the chosen brand name is distinctive, the trademark may be submitted. Find ways to change
     or modify your brand name or logo if it has already been registered by someone else to prevent the cancellation of your 
     registration procedure.`,
    ],
    body: [
      {
        head: "How its done?",
        points: `This service has ene-to-end online fulfillment provided by our professional.`,
        icon: true,
      },
      {
        points: `Getting an application ready`,
        icon: true,
      },
      {
        points: `Utilizing a logo and tagline`,
        icon: true,
      },
      {
        points: `Online filing on your trademark`,
        icon: true,
      },
      {
        head: "Important trademark registration details",
        points: `Important details regarding India's online trademark registration process`,
        icon: true,
      },
      {
        points: `R Symbol`,
        icon: true,
      },
      {
        points: `TM Symbol`,
        icon: true,
      },
      {
        points: `SM Symbol`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const tradeCiData = {
    id: "documents",
    background: "main-content",
    mt_div: "mt-3",
    header: "Documents Required For Trademark Registration",
    paragraph: [
      `We can sign up for the trademark registration process if you provide a formal letter that is officially signed by you. 
    You can submit the application online and pay the required brand name registration cost after getting the authorization letter and the 
    required documentation. Upon obtaining the application confirmation following the verification procedure, you will be granted permission 
    to use the TM symbol.`,
      `The documents you must submit as part of the online trademark registration procedure are listed below:`,
    ],
    points: [
      {
        content: `MSME certificate`,
        icon: true,
      },
      {
        content: `Type of business`,
        icon: true,
      },
      {
        content: `Registration address`,
        icon: true,
      },
      {
        content: `Brand/logo/slogan name`,
        icon: true,
      },
      {
        content: `Aadhar Card of the applicant.`,
        icon: true,
      },
      {
        content: `Identity proof and address proofs`,
        icon: true,
      },
      {
        content: `Incorporation certificate or Registration certificate`,
        icon: true,
      },
      {
        content: `The social category of the applicant- Gender and Physical
        status of the applicant- physically disabled or not.`,
        icon: true,
      },
    ],
    image: "/imgs/business/document-pvt.png",
    alt_tag: "Required Documents for Trademark Registration",
  };

  /* ImgSlider Data */
  const MsMeImages = [
    "/imgs/registration/ipr/td1.png",
    "/imgs/registration/ipr/td2.png",
    "/imgs/registration/ipr/td4.png",
  ];

  /*  Slider Content Component Data */
  const tradeSCData = {
    id: "benefitss",
    background: [],
    mt_div: "mt-3",
    header: "Benefits Of Trademark Registration",
    content: [
      {
        points: `A legal protection`,
        icon: true,
      },
      {
        points: `Global filing of registered mark`,
        icon: true,
      },
      {
        points: `Business opportunity`,
        icon: true,
      },
      {
        points: `Obtain your unique identity`,
        icon: true,
      },
      {
        points: `Fewer
        Electricity Bills`,
        icon: true,
      },
    ],
    images: MsMeImages,
  };

  var FAQ_data = [
    {
      header: "What is Trademark?",
      body: [
        {
          content: ` Your brand name or emblem, which distinguishes your goods or
          services, is your trademark. Words, letters, numbers, phrases,
          symbols, shapes, or any mix of these can all be used. To set your
          product or service apart from others in the market, utilize a
          distinctive brand name or logo. Get your brand name or logo
          registered if you want legal protection for it. You can begin using
          TM after you receive your TM Application number.`,
          icon: false,
        },
      ],
    },

    {
      header: "Why should i file application for trademark?",
      body: [
        {
          content: `Get exclusive rights to your brand by registering it.`,
          icon: true,
        },
        {
          content: `Like all of your company's other assets and possessions, protect
          your brand.`,
          icon: true,
        },
        {
          content: `You can better safeguard your hard-earned goodwill in the
          marketplace.`,
          icon: true,
        },
        {
          content: `You will have statewide protection for your business.`,
          icon: true,
        },
        {
          content: `You can prevent others from advertising the same brand in the same
          sector of commerce.`,
          icon: true,
        },
      ],
    },
    {
      header: "Can I get global trademark?",
      body: [
        {
          content: `No. Because trademarks are territorial in nature, if you register
          one in India, it will only be good for India.`,
          icon: false,
        },
      ],
    },
    {
      header: "  How long is a trademark registration valid?",
      body: [
        {
          content: `The registered trademark is valid for ten years from the application
          filing date. The trademark may be extended one more.`,
          icon: false,
        },
      ],
    },
    {
      header: "When may I use the symbols R or TM?",
      body: [
        {
          content: `Following the filing of a trademark application, the symbol "TM" may
          be used. Only when a trademark has been registered should "R" be
          used. Only the products and services indicated on the registration
          certificate are eligible to use the ® emblem.`,
          icon: false,
        },
      ],
    },
    {
      header: "Who needs Trademark registration?",
      body: [
        {
          content: `Any word, name, device, label, numerals, or a combination of colors that can be represented 
          graphically can be registered as a trademark. The trademark to be registered must also be distinctive 
          for the services or goods for which it is proposed to be registered.`,
          icon: false,
        },
      ],
    },
    {
      header: "Which Trademarks cannot be registered?",
      body: [
        {
          content: `Any mark which is identical or similar to an existing registered trademark or any trademark 
          for which an application has been made, cannot be registered. Also, marks that would likely cause 
          deception or confusion or are offensive in any way may not be registered. Geographical names, 
          common names, common trade words and common abbreviation can also not be registered as a trademark.`,
          icon: false,
        },
      ],
    },
    {
      header: " Is my trademark application valid across the world?",
      body: [
        {
          content: `No, any trademark registered in India will be valid only in India. However, some 
          countries use the trademark filing in India as a basis for registering the trademark in their country.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "Can a foreigner or foreign entity get a trademark registered in India?",
      body: [
        {
          content: `Yes, a foreign person or a foreign entity can apply for trademark registration in India.`,
          icon: false,
        },
      ],
    },
    {
      header: "How long it takes to obtain Trademark registration?",
      body: [
        {
          content: `Trademark application can be filed with the trademark registry in a matter of 1 - 3 days. 
          However, it will typically take 6 to 24 months for the registry to complete their formalities and provide 
          registration for the trademark.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Trademark Registration'
        keywords='Best Trademark Registration in Hosur,Best Trademark Registration near Bangalore,Trademark Registration in Chennai'
        description='TODAYFILINGS is the one of the best online trademark registration service in Hosur'
        name='TODAYFILINGS'
        type='Online E-filing'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={tradeSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={tradeAboutData} />

          <Counter />
          <ScrollNav scroll_data={MsMeScrollId} />
          <ImgContent item={tradeIcData} />
          <ContentForm CFSection_data={tradeCfData} />
          <SliderContent ScSection_data={tradeSCData} />
          <ContentImg CISection_data={tradeCiData} />

          <Guidance />

          <Cta />
          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
